<template>
  <module-container
    :title="$t('accountingentryinvoices')"
    route-name="accountingentryinvoices"
    show-toolbar
    hide-new-button
    hide-back-button
  >
    <template #buttons>
      <accounting-entry-invoice-export />
      <accounting-entry-invoice-generate />
    </template>

    <template #route>
      <router-view />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import AccountingEntryInvoiceGenerate from "@/modules/accountingentryinvoices/components/AccountingEntryInvoiceGenerate.vue";
import AccountingEntryInvoiceExport from "@/modules/accountingentryinvoices/components/AccountingEntryInvoiceExport.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { AccountingEntryInvoiceExport, AccountingEntryInvoiceGenerate },
})
export default class AccountingentryinvoicesIndex extends Mixins(
  PaginateProviderMixin
) {}
</script>
